

































































































import { Vue, Component, Prop } from "vue-property-decorator";
import adminService from "@/services/AdminService";
import { Profile } from "@//models/Profile";
import { OrganisationDetails } from "@/models/OrganisationDetails";
import { organisationModule } from "@/store/modules/OrganisationModule";

@Component
export default class ManualEmissions extends Vue {
  private emission = {
    year: new Date().getFullYear(),
    month: 1,
    co2: 0,
    quantity: 0,
    cost: 0,
    emissionTypeId: null,
    organisationUnitId: null,
  };

  private get organisation(): OrganisationDetails {
    return organisationModule.organisation;
  }

  private async createEmission() {
    await adminService.createManualEmission(
      this.organisation.id,
      this.emission
    );
  }
}
